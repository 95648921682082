import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Flex } from "rebass";
import * as JsSearch from "js-search";
import BlogLayout from "../components/BlogLayout";
import BlogCard from "../components/BlogCard";
import colors from "../utils/theme/colors";
import SortByDate from "../utils/sortByDate";

const BlogSearchPage = ({
  location: { search },
  data: {
    allBlogs: { edges: allBlogs },
    site: {
      siteMetadata: { siteUrl, socialLinks }
    },
    blogPageInfo: {
      frontmatter: { longTitle, title }
    },
    bgMobile: {
      childImageSharp: { fluid: bgMobileFluid }
    }
  }
}) => {
  const urlParams =
    search &&
    JSON.parse(
      `{"${decodeURI(search.slice(1, search.length))
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    );
  const dataToSearch = new JsSearch.Search(["node", "frontmatter", "title"]);
  dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
  dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
  dataToSearch.addIndex(["node", "frontmatter", "introtext"]);
  dataToSearch.addIndex(["node", "html"]);
  dataToSearch.addIndex(["node", "frontmatter", "title"]);
  dataToSearch.addIndex(["node", "frontmatter", "tags"]);
  dataToSearch.addDocuments(allBlogs);
  const searchResult = dataToSearch.search(
    urlParams && urlParams.search ? urlParams.search : ""
  );
  const blogsSort = SortByDate(searchResult);
  return (
    <BlogLayout
      allBlogs={allBlogs}
      socialLinks={socialLinks}
      slug={`${siteUrl}/blog-search`}
      bgMobileFluid={bgMobileFluid}
      longTitle={longTitle}
      socialTitle={title}
    >
      <Flex
        width="100%"
        flexDirection="column"
        ml={[0, 0, "-44px"]}
        mr={[0, 2, "105px"]}
      >
        {!searchResult.length &&
          `There were no search results for the search "${urlParams.search}". Please try using more general terms to get more results.`}
        {searchResult.length &&
          blogsSort.map(data => (
            <BlogCard
              key={data.node.id}
              mb={8}
              data={data}
              css={{ backgroundColor: colors.white }}
            />
          ))}
      </Flex>
    </BlogLayout>
  );
};

export const query = graphql`
  {
    bgMobile: file(relativePath: { eq: "images/bg-mobile.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    blogPageInfo: markdownRemark(frontmatter: { name: { eq: "blog" } }) {
      frontmatter {
        longTitle
        title
      }
    }
    allBlogs: allMarkdownRemark(
      filter: { frontmatter: { collection_name: { eq: "blog-posts" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          html
          frontmatter {
            category
            postImage {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            date
            title
            introtext
            author
            tags
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        socialLinks {
          icon
          url
        }
      }
    }
  }
`;

BlogSearchPage.propTypes = {
  data: PropTypes.shape().isRequired,
  location: PropTypes.shape().isRequired
};

export default BlogSearchPage;

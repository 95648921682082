import * as React from "react";
import PropTypes from "prop-types";
import { Box, Flex } from "rebass";

import Link from "../Link";
import Image from "../Image/BlogImage";
import { BlogH3, BlogP, Span } from "../Typography";

import colors from "../../utils/theme/colors";

const BlogCard = ({
  data: {
    node: {
      id,
      fields: { slug },
      frontmatter: {
        category,
        title,
        introtext,
        author,
        date,
        postImage: {
          childImageSharp: { fluid: postImage }
        }
      }
    }
  },
  ...props
}) => (
  <>
    <Link color={colors.secondary2} colorOnHover={colors.secondary2} to={slug}>
      <Flex
        {...props}
        width={1}
        flexDirection="column"
        key={id}
        css={{ textAlign: "left" }}
      >
        <Image
          imgStyle={{ objectPosition: "center right" }}
          style={{ height: "100%" }}
          fluid={postImage}
          fadeIn
        />
        <Box p={4}>
          <Flex
            flexDirection={["column", "column", "row", "row"]}
            justifyContent="space-between"
            mb={3}
          >
            <Box mb={[2, 2, 1, 0]} width={[1, 1, 0.6, 0.6]}>
              <BlogH3>{title}</BlogH3>
            </Box>
            <Box css={{ display: "table" }}>
              <Span py="5px" px={1} backgroundColor={colors.primary3}>
                {category}
              </Span>
            </Box>
          </Flex>
          <BlogP mb={5}>{introtext}</BlogP>
          <Flex
            justifyContent="space-between"
            flexDirection={["column", "row", "row", "row"]}
          >
            <Flex flexWrap="wrap" mb={[1, 1, 0, 0]}>
              <Span mr={[0, "3px"]}>
                {new Date(date).toDateString().slice(4)}
              </Span>
              <Span color={colors.primary1}>
                <Span mr="3px" color={colors.secondary2}>
                  | by
                </Span>
                {author}
              </Span>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Link>
  </>
);

BlogCard.propTypes = {
  data: PropTypes.shape({
    node: PropTypes.shape({
      id: PropTypes.string,
      fields: PropTypes.shape(),
      frontmatter: PropTypes.shape({
        postImage: PropTypes.shape(),
        category: PropTypes.string,
        title: PropTypes.string,
        introtext: PropTypes.string,
        author: PropTypes.string,
        date: PropTypes.string
      })
    })
  }).isRequired
};

export default BlogCard;
